.notificationCard:hover{
    scale: 1.03 1.1;
    transition: all 0.5s;
    cursor: pointer;
}

.markAllasRead:hover{
    cursor: pointer;
}
.markAllasRead{
    text-decoration: underline;
}

.loaderStyle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 90vh;
    align-items: center;
}

.taggedPerson{
    font-weight: bolder;
    color: rgba(3, 3, 3, 0.507);
    
}