/* Customizable Area Start */

.sidelogo {
    width: 400;
     height: 180;
}

.logos { 
    width: 100;
    height: 100;
    align-self: "center" ;
}

.newcontain {
    align-items: "center";
    display: "flex";
    flex-direction: "column";
}

.label {
    color: "black";
    font-weight: 600;
    font-size: "22px";
    background-color: white;
  }

  .textlabel { 
    color: "#59788ec4"; 
    font-weight: 600; 
}

.inputs {
    margin-bottom: 15;
    font-weight: 700;
    font-size: "16px";
    color: "#6a6f7a";
      
}

.textbox { 
    border-radius: "6px"; 
    font-size: "12px" ;
}

.icons {
    width: 30;
    height: 30; 
}

.errors { 
    color: "red" 
}

.mustcontain { 
    margin: 15;
    color: "#9fa0a2";
}
.mustcontains { 
    color: "#9fa0a2" 
}

.insidecontent { 
    color: "#777777"; 
    font-weight: "bold"; 
}

.mini {
    margin: 10;
}

.atleast {
    margin-left: 10;
}

.buttons {
    padding-left: "42%";
    padding-right: "42%";
    text-transform: "none";
    background-image:
      "radial-gradient(rgb(246 168 34), rgb(171 114 24))";
    color: "black";
    font-weight: "bold";
    border-radius: "6px";
    height: "45px";
    margin-top: "5%";
  }

  .modalbackdrop {
    position: "fixed";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: "rgba(0, 0, 0, 0.4)";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    z-Index: 2;
}

.modalcontentsuccess {
    width: "20%";
    height: "30%";
    font-family: "sans-serif";
    background-color: "white";
    text-align: "center";
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

.modalbox { 
    display: "flex";
    justify-content: "center";
}

.modalcheck { 
    width: "250px";
    height: "150px"; 
}

.modalcontent {
    font-size: "16px";
    font-family: "sans-serif";
    font-weight: "bold";
}

.modalbutton {
    padding-left: "25%";
    padding-right: "25%";
    padding-top: "2%";
    padding-bottom: "2%";
    /* background-color: "#dd951f";
    color: "black"; */
    font-weight: "bold";
    border-radius: "6px";
    width: "50px";
    height: "auto";
    margin-top: "13px";
    display: "flex";
    /* background-image:
      "radial-gradient(rgb(246 168 34), rgb(171 114 24))"; */
      text-transform: none;
      background-color: "#e9e9e9";
      color: "536c7c";
  }

  .forgotcondition {
    align-items: "center";
    display: "flex"; 
    flex-direction: "column";
}

.otps {
    display: "flex"; 
    align-items: "center";
    align-content: "center";
    justify-content: "center";
}

.inputStyles {
    width: "3rem";
    height: "3rem";
    margin: "0 1rem";
    font-size: "2rem";
    border-radius: 4;
    border: "1px solid rgba(0,0,0,0.3)";
}

OtpInput {
    width: 30px;
    height: 50px;

}
 /* Customizable Area End */