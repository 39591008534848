body{
    font-family: sans-serif;
    font-size: 15px;
}

.buttonText{
    font-weight: bold;
    font-size: 15px;
    text-transform: none;
}

.genText{
    font-size: 13px;
}

.tHead td{
    font-weight: bold;
    /* padding-top: 10px ;
    padding-bottom: 10px ;     */
    padding: 10px 5px;    
}

.tCell td{
    border-bottom: none;
}

.finImage{
    background-size: contain;
    width: 100%;
    height: 100%;
}

.totalAmount div{
    display: flex;
    justify-content: space-between;
}

.signature{
    background-size: contain;
}

.billTable td{
    border: 1px solid black;
    padding: 20px 10px;
}

.billLastRow td{
    border-bottom: 1px solid black;
}

.icon:hover{
    scale: 1.2;
    color: teal;
    cursor: pointer;
  }

  .modalLabel{
    color:#404040
  }
  .hoverPointer:hover{
    cursor: pointer;
  }