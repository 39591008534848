.btn-cancel{
    /* // padding:'5px 25px 5px 25px', */
    margin-top: 2% !important;
    margin-left: 15px !important;
    background-color:#e8e8e8 !important;
    color: black !important;
    /* //   marginLeft: "15px"; */
    padding: 10px 35px 10px 35px !important;
    font-family: "Roboto",sans-serif !important;
    font-size: 16 !important;
    width: 130px !important;
    text-transform: none !important;
    font-weight: bold !important;
}
.btn-add{
    margin-top: 2% !important;
    margin-left: 15px !important;
    background-color: #0096FF !important;
    color: white !important;
    font-family: "Roboto",sans-serif !important;
    font-size: 16 !important;
    padding: 10px 35px 10px 35px !important;
    width: 130px !important;
    text-transform: none !important;
    font-weight: bold !important;

}
.saveadminbtn{
  margin-top: 2% !important;
    margin-left: 15px !important;
    background-image: radial-gradient(rgb(246 168 34), rgb(171 114 24)) !important;
    /* background-color: #0096FF !important; */
    color: "white" !important;
    font-family: "Roboto",sans-serif !important;
    font-size: 16 !important;
    padding: 10px 35px 10px 35px !important;
    width: 130px !important;
    text-transform: none !important;
    font-weight: bold !important;
}
.modal-content {
    width: 50%;

    /* height: 80%; */
    min-height: 400px;
    max-height: 80%;
    background-color: white;
    padding: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .mainheading{

    font-family: 'Roboto', sans-serif !important;
    color: #6a6f7a !important;
    text-align: left !important;
    font-weight: 700 !important;
    font-size: 16px !important;

    color:black !important;
    display:flex !important;
    margin-bottom:10 !important;
  }
  .modal-successcontent{
    width: 30% !important;
    height: 40% !important;
    /* min-height: 200px; */
    background-color: white !important;
    padding: 25px !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    /* overflow-y: scroll; */
  }
  .okbtn{
    margin-top: 2% !important;
    margin-left: 15px !important;
    background-color: #e8e8e8 !important;
    color: black !important;
   width: 30%;
    padding: 10px 35px 10px 35px !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 16 !important;
  }