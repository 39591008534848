.labletext{
    color: #818589;
    font-family: "Roboto",sans-serif !important;
    font-weight: bold;
    font-size: 15;
    padding-bottom: 0;
}
.selectedLabledText{
    color: #0096FF !important;
    font-family: "Roboto",sans-serif !important;
    font-weight: bold;
    font-size: 15;
    padding-bottom: 0;
}
.adminselected{
    color: white!important;
    font-family: "Roboto",sans-serif !important;
    font-weight: bold;
    font-size: 15;
    padding-bottom: 0;
}
.clientname{
    font-family: sans-serif !important;
    font-weight: bold;
    font-size: 16;
    text-align: left !important;
    background-color:"red" !important;
    margin-left: 15px !important;

}
.subclientname{
    font-family: "Roboto",sans-serif !important;
    text-align: left !important;
    /* font-weight: bold; */
    font-size: 15;
    color: grey !important;
    margin-left: 15px !important;
}

.drawers{
    background-color: black;
}

.noficationBox{
   overflow-y: scroll;
   max-height: 70vh;
   overflow-x: hidden;
   width: 100%;
}

.noficationBox::-webkit-scrollbar{
   width: 0.3rem;
}

.noficationBox::-webkit-scrollbar-track{
   box-shadow:  'inset 0 0 6px rgba(0,0,0,0.00)';
   
}

.noficationBox::-webkit-scrollbar-thumb{
    background-color: #E1E0E5;
    border-radius: 5px;
    margin-right: 10px;
}

.markAllRead:hover{
    cursor: pointer;
}
.markAllRead{
    text-decoration: underline;
}