/* Customizable Area Start */
html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.containerParent {
  height: 100%;
  max-width: 100%;
  position: fixed;
  font-family: sans-serif;
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}

.MuiFormLabel-root {
  color: black;
  padding: 0;
  font-size: 22px;
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.label {
  font-size: 16px;
  color: rgb(106,111,122);
  font-weight: 700;
}

.caption {
  font-size: 16px;
  color: rgb(89,120,142,0.77);
  font-weight: 600;
}
::-webkit-scrollbar {
  /* TODO: changed this to width 0 from display:none, so can show sidebar where needed. */
  width: 0;
 }

 /* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #bdbdbd;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.87);
} */
/* Customizable Area End */