 /* Customizable Area Start */

 .errors {
    color: "red";
}

.modal-backdrop-login {
   position: fixed;
   z-index: 1300;
   inset: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.modal-content-success-login {
   max-width: 60%;
   max-height: 65vh;
   row-gap: 5px;
   font-family: sans-serif;
   overflow: auto;
   /* height:40%; */
   /* min-height: 200px; */
   background-color: white;
   padding: 5px 20px;
   /* text-align: center; */
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   padding-bottom: 20px !important;
   /* max-width: 60%;
   font-family: sans-serif;
   overflow: auto;
   max-height: 65vh;
   row-gap: 5px;
   min-height: 200px;
   background-color: white;
   padding: 5px 20px;
   display: flex;
   flex-direction: column;
   padding-bottom: 20px !important; */
}

.modal-content-success-login>div>ol>li{
   margin: 20px 0px;
 
 }
/* Customizable Area End */